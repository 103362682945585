import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import LineGraph from './LineGraph';
import { CardContent } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DataTable from '../../components/datatable/DataTable';

import './ReportSubscription.css'

// Constants
const defaultStyle = {
    cellStyle: {
        textAlign : 'center',
        openModal: false
    },
  }

class ReportSubscription extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fromDate: new Date('2020-12-01T00:00:00'),
            toDate: new Date(),
            dataForTable: [],
            allData: [],
            graphData: [],
            totalAmount: 0
        };
    }

    componentDidMount(){

        let allData = this.props.subscriptionData.map(subs => {
            const user = this.props.usersData.find((value) => value.customer === subs.customer_id )
            const price = this.props.pricesData.find((value) => value.price_id === subs.plan.id )
            subs.user = user
            subs.price = price
            return subs
        })
        
        const graphData = this.sortOutByDate(allData)
        
        const totalAmount = graphData.reduce((total, num)=> total + num);

        this.setState({ allData: allData, dataForTable: allData, graphData, totalAmount })
    }

    sortOutByDate = (allData) => {
        const currentYear = new Date().getFullYear()
        let graphData = {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0
        }

        for (let i = 0; i < allData.length; i++) {
            const date = new Date(allData[i].current_period_start * 1000)
            const valid =  date.getFullYear() === currentYear ? true : false
            if (valid) {
                graphData[date.getMonth()+1] += allData[i].plan.amount/100
            }
        }

        graphData = Object.values(graphData)
        return graphData
    }


    handlerDateInput = (value, input)=>{
        this.setState({ [input]: value }, ()=> this.checkData())
      }
    
      checkData = () =>{
        let totalAmount = 0

        const allData = this.state.allData
        let result = allData
        result = result.filter( value => {
          if(new Date(value.start_date *1000) >= this.state.fromDate && new Date(value.start_date *1000) <= this.state.toDate){
            totalAmount += value.plan.amount/100
            return value
          }
        })

        this.setState({ dataForTable: result, totalAmount })
      }

    render(){
        const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const columns = [
          { 
            title: 'Buy Date', 
            field: 'current_period_start',
            render: rowData => {
              const date = new Date(rowData.current_period_start * 1000).toLocaleDateString('en-US', {hour: 'numeric', year: 'numeric', month: 'long', day: 'numeric' })
              return(
                date
                )
              },
              ...defaultStyle
            },
            { 
              title: 'Expiration date', 
              field: 'current_period_end',
              render: rowData => {
                const date = new Date(rowData.current_period_end * 1000).toLocaleDateString('en-US', {hour: 'numeric', year: 'numeric', month: 'long', day: 'numeric' })
                return(
                  date
                  )
                },
                ...defaultStyle
              },
          { 
            title: 'Price Code', 
            field: 'price.price_id',
            ...defaultStyle
          },
          { 
            title: 'Price Name', 
            field: 'price.nickname',
            ...defaultStyle
          },
          { 
            title: 'User', 
            field: 'user.email', 
            ...defaultStyle
          },
          { 
            title: 'Duration', 
            field: 'plan.interval', 
            ...defaultStyle
          },
          { 
            title: 'Amount', 
            field: 'plan.amount',
            render: rowData => {
              const amount = rowData.plan.amount / 100
              return(
                amount
                )
              },
            ...defaultStyle
          },
          { 
            title: 'Subscription id', 
            field: 'subscription_id', 
            ...defaultStyle
          }
        ]
        return (
           <Grid container style={{paddingTop: '32px'}}>

                <Grid container justify="space-between" alignItems="center">
                    
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12} md={5}>
                        <KeyboardDatePicker
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline-1"
                        label="Date picker inline"
                        value={ this.state.fromDate }
                        onChange={(e)=> this.handlerDateInput(e, 'fromDate')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                    </Grid>
                    
                    <Grid item xs={12} md={5}>
                        <KeyboardDatePicker
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline-2"
                        label="Date picker inline"
                        value={ this.state.toDate }
                        onChange={(e)=> this.handlerDateInput(e, 'toDate')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                    </Grid>
                    </MuiPickersUtilsProvider>
                    
                </Grid>
              
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{paddingTop: '32px'}}>
                    <Grid container spacing={3}>
                        <CardContent style={{width: "100%"}}>
                        <DataTable 
                            title="Subscriptions List" 
                            data={[...this.state.dataForTable]} 
                            columns={columns} 
                            grouping={true}
                            selection={false}
                            exportButton={true}
                            toolbar={true}
                            noActions={true}
                        />
                        </CardContent>
                    </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <div className="container-total-amount">
                        <div>
                            Total Sales: 
                        </div>

                        <div className="total-amount" >
                            $ { this.state.totalAmount }
                        </div>                     
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <LineGraph
                    data={ this.state.graphData }
                    labels={labels}
                    title="Total sales per month"
                    />
                </Grid>
           </Grid>
        )
    }
};

export default ReportSubscription;