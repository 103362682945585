import React from 'react';
import MenuUsers from '../../components/menu_users/MenuUsers';

const myHTML = `


<!-- header area start  -->
<header class="header-simple-area header-bg-4 style-four" id="home">
</header>
<!-- header area end  -->

<!-- video area start -->
<section class="legal-area">
  <div class="top-bg"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="right-content-area-terms">
                    <h1 class="title">Terms & Conditions</h1>
                    <hr />
                    <br />
                    <p><b>Welcome to ClueGarden!</b></p>
                    <p>Please only use this service if you agree with the following terms and conditions.</p>
                    <br />
                    <h5><b>Privacy Policy</b></h5>
                    <p>We take your privacy seriously, and we are committed to protecting your personal data. Please refer to our privacy policy for information on how we collect and use it. You agree that your use of ClueGarden is subject to our privacy policy.</p>
                    <br />
                    <h5><b>Using our Service</b></h5>
                    <p>You may use ClueGarden only if you are 13 years or older and are not barred from using the service under applicable law. To make a purchase via ClueGarden, you must be 18 years or older and capable of forming a binding contract.</p>
                    <p>You understand and agree that our service and any information you learn from ClueGarden are not intended, designed, or implied to diagnose, prevent, or treat any condition or disease, to ascertain the state of your health, or to be a substitute for professional medical care.</p>
                    <p>Not all activities suggested on ClueGarden are suitable for everyone. Do not use the application while driving, operating heavy machinery, or performing other tasks that require attention and concentration. You understand and agree that you are solely responsible for your use of ClueGarden.</p>
                    <br />
                    <h5><b>Changes to our Service</b></h5>
                    <p>Our service is evolving over time, so we may change or discontinue all or any part of it, at any time and without notice, at our sole discretion.</p>
                    <br />
                    <h5><b>Access</b></h5>
                    <p>If we provide you with a user ID and password to access restricted areas, you must ensure that the user ID and password are kept confidential.</p>
                    <p>You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
                    <p>ClueGarden may disable your user ID and password at ClueGarden’s sole discretion without notice or explanation.</p>
                    <br />
                    <h5><b>User Content</b></h5>
                    <p>Your written reflections, profile picture, and other user content must not be illegal or unlawful, must not infringe any third party's legal rights, and must not be capable of giving rise to legal action whether against you or ClueGarden or a third party.</p>
                    <p>You must not submit any user content to the website that is or has ever been the subject of any threatened or actual legal proceedings or other similar complaint.</p>
                    <p>ClueGarden will not assume any legal responsibility for any infringement caused by any user, and that responsibility will fall solely on the user. ClueGarden reserves the right to edit or remove any material submitted to us.</p>
                    <br />
                    <h5><b>Content Ownership</b></h5>
                    <p>ClueGarden exclusively owns all right, title and interest in and to the service we provide and its content, including all associated intellectual property rights. You acknowledge that our service and content are protected by copyright, trademark, and other laws of the United States and foreign countries.</p>
                    <p>You agree not to remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Services or Content.</p>
                    <br />
                    <h5><b>Purchases</b></h5>
                    <p>ClueGarden offers a service which you can purchase as a monthly or yearly subscription. We may ask you to supply relevant information, such as your credit card number, the expiration date of your credit card and your billing address.</p>
                    <p>You represent and warrant that you have the legal right to use all payment method(s) represented by any such payment information. The amounts due and payable by you will be presented to you before you place your order.</p>
                    <p>If you choose to initiate a payment, you authorize us to provide your payment information to third-party service providers so we can complete your order and agree:</p>
                      <ul class="terms-ul">
                        <li>to pay the applicable fees and any taxes,</li>
                        <li>that ClueGarden may charge your credit card or third-party payment processing account, including, but not limited to, your account with the app store or distribution platform where ClueGarden is made available, for verification, preauthorization and payment purposes,</li>
                        <li>to bear any additional charges that your app provider, bank or other financial service provider may levy on you as well as any taxes or fees that may apply.</li>
                      </ul>
                    <br />
                    <p>You’ll receive a confirmation email after we confirm the payment for your order. Your order is not binding on ClueGarden until accepted and confirmed by us. All payments made are non-refundable and non-transferable except as expressly provided in these terms.</p>
                    <p>If you have any concerns or objections regarding charges, you agree to raise them with us first and you agree not to cancel or reject any credit card or third-party payment processing charges unless you have made a reasonable attempt at resolving the matter directly with ClueGarden.</p>
                    <p>ClueGarden reserves the right to not process or to cancel your order in certain circumstances, for example, if your credit card is declined, if we suspect the request or order is fraudulent, or in other circumstances we deem appropriate at our sole discretion.</p>
                    <p>ClueGarden also reserves the right, at our sole discretion, to take steps to verify your identity in connection with your order. You may need to provide additional information to verify your identity before completing your order. We will either not charge you or refund the charges for orders that we do not process or cancel.</p>
                    <br />
                    <h5><b>Changes to Pricing</b></h5>
                    <p>ClueGarden reserves the right to change its pricing terms for subscriptions at any time and we will notify you in advance of such changes becoming effective. Changes to the pricing terms will not apply retroactively and will only apply for subscription renewals after such changed pricing terms have been communicated to you.</p>
                    <p>If you do not agree with the changes to ClueGarden’s pricing terms then you may choose not to renew your subscription.</p>
                    <br />
                    <h5><b>Future Functionality</b></h5>
                    <p>You agree that your purchases are not contingent on the delivery of any future functionality or features, or dependent on any oral or written public comments made by ClueGarden regarding future functionality or features.</p>
                    <br />
                    <h5><b>Termination</b></h5>
                    <p>We may terminate or suspend access to our service immediately, without prior notice or liability, for any reason whatsoever, including without limitation, if you breach these terms and conditions. Upon termination, your right to use the service will immediately cease.</p>
                    <p>All provisions of these terms and conditions which by their nature should survive termination shall survive termination.</p>
                    <br />
                    <h5><b>No Warranties</b></h5>
                    <p>This service is provided “as is” without any representations or warranties, expressed or implied. Without prejudice to the generality of the foregoing, ClueGarden does not warrant that:</p>
                      <ul class="terms-ul">
                        <li>this service will be constantly available, or available at all, or</li>
                        <li>the information on this platform is complete, true, accurate or non-misleading.</li>
                      </ul>
                    <br />
                    <h5><b>Limitations of liability</b></h5>
                    <p>ClueGarden will not be liable to you in relation to the contents of, or use of, or otherwise in connection with, this application:</p>
                      <ul class="terms-ul">
                        <li>for any indirect, special or consequential loss, or</li>
                        <li>for any business losses, loss of revenue, income, profits or anticipated savings, loss of contracts or business relationships, loss of reputation or goodwill, or loss or corruption of information or data.</li>
                      </ul>
                    <br />
                    <p>These limitations of liability apply even if ClueGarden has been expressly advised of the potential loss.</p>
                    <br />
                    <h5><b>Indemnity</b></h5>
                    <p>You will indemnify and hold harmless ClueGarden and its officers, directors, employee and agents, from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of our service or content or your violation of these terms.</p>
                    <br />
                    <h5><b>Changes to Terms and Conditions</b></h5>
                    <p>We reserve the right, at our sole discretion, to modify or replace these terms and conditions at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
                    <p>By continuing to access or use our service after those revisions become effective, you agree to be bound by the revised terms and conditions. If you do not agree to the new terms and conditions, please stop using the service.</p>
                    <br />
                    <h5><b>Governing Law</b></h5>
                    <p>These terms and conditions shall be governed and construed in accordance with the laws of North Carolina, United States, without regard to its conflict of law provisions.</p>
                    <p>Our failure to enforce any right or provision of these terms and conditions will not be considered a waiver of those rights. If any provision of these terms and conditions is held to be invalid or unenforceable by a court, the remaining provisions of these terms and conditions will remain in effect. These terms and conditions constitute the entire agreement between us regarding our service, and supersede and replace any prior agreements.</p>
                    <br />
                    <h5><b>Unenforceable provisions</b></h5>
                    <p>If any provision of this website disclaimer is, or is found to be, unenforceable under applicable law, that will not affect the enforceability of the other provisions of this website disclaimer.</p>
                    <br />
                    <h5><b>Contact Us</b></h5>
                    <p>If you have any questions about these terms and conditions, please <a href="index.html#contact"><b>contact us</b></a>.</p>
                    <br />
                    <p><b>Thank you. And now, enjoy ClueGarden! ☺</b></p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- video area end -->

<!-- footer area start -->
<footer class="footer-area">
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-5">
                    <div class="footer-widget about_widget">
                        <a href="index.html" class="footer-logo"><img src="assets/img/logo-white.png" alt=""></a>
                        <p>A guide for your self-discovery adventure.</p>
                        <ul class="social-icon">
                            <li><a href="https://www.facebook.com/cluegarden" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="https://www.instagram.com/cluegarden/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                            <li><a href="https://twitter.com/cluegarden" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/cluegarden" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6">
                    <div class="footer-widget nav_menus_widget">
                        <h4 class="widget-title">Useful Links</h4>
                        <ul>
                            <li><a href="index.html"><i class="fas fa-chevron-right"></i> Home</a></li>
                            <li><a href="index.html#what"><i class="fas fa-chevron-right"></i> What</a></li>
                            <li><a href="index.html#how"><i class="fas fa-chevron-right"></i> How</a></li>
                            <li><a href="index.html#faqs"><i class="fas fa-chevron-right"></i> FAQs</a></li>
                            <li><a href="index.html#pricing"><i class="fas fa-chevron-right"></i> Pricing</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6">
                    <div class="footer-widget nav_menus_widget">
                        <h4 class="widget-title">Need help?</h4>
                        <ul>
                            <!-- <li><a href="#"><i class="fas fa-chevron-right"></i> Faqs</a></li> -->
                            <li><a href="index.html#contact"><i class="fas fa-chevron-right"></i> Support</a></li>
                            <li><a href="privacy"><i class="fas fa-chevron-right"></i> Privacy Policy</a></li>
                            <li><a href="terms"><i class="fas fa-chevron-right"></i> Terms &<br /> &nbsp; &nbsp; Conditions</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area"><!-- copyright area -->
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="copyright-inner"><!-- copyright inner wrapper -->
                        <div class="left-content-area"><!-- left content area -->
                            &copy; ClueGarden. All rights reserved.
                        </div><!-- //. left content aera -->
                    </div><!-- //.copyright inner wrapper -->
                </div>
            </div>
        </div>
    </div><!-- //. copyright area -->
</footer>
<!-- footer area end -->


  <!-- back to top area start -->
  <div class="back-to-top">
        <i class="fas fa-angle-up"></i>
  </div>
  <!-- back to top area end -->

  `

  export  default function Privacy() {
    return (
        <div>
            <MenuUsers/>
            <div dangerouslySetInnerHTML={{ __html: myHTML }} />
        </div>
    );
  }