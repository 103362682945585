import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import LineGraph from './LineGraph';
import { CardContent } from '@material-ui/core';
import DataTable from '../../components/datatable/DataTable';

import './ReportPlansComparisons.css'

// Constants
const defaultStyle = {
    cellStyle: {
        textAlign : 'center',
        openModal: false
    },
  }

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

class ReportPlansComparisons extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataForTable: [],
            months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            allData: [],
            graphData: [],
            columns: []
        };
    }

    componentDidMount(){

        let allData = this.props.subscriptionData.map(subs => {
            const user = this.props.usersData.find((value) => value.customer === subs.customer_id )
            const price = this.props.pricesData.find((value) => value.price_id === subs.plan.id )

            subs.user = user
            subs.price = price
            return subs
        })

        const tableData = this.sortOutByPlansDate(allData)

        let planColumns = { 
            title: 'Month', 
            field: 'month',
            render: rowData => {
              return(
                  this.state.months[rowData.tableData.id]
                )
              },
            ...defaultStyle
          }
          
        const planColumnsData = this.props.pricesData.map(value => {
            return { 
              title: value.nickname, 
              field: value.price_id+'.mount',
              ...defaultStyle
            }  
        })

        planColumns = [planColumns, ...planColumnsData]

        let graphLine = this.createGraph(tableData)        

        this.setState({ columns: planColumns, dataForTable: tableData, graphData: graphLine })
    }

    sortOutByPlansDate = (allData) => {
        const currentYear = new Date().getFullYear()

        let graphData = {
            0: {},
            1: {},
            2: {},
            3: {},
            4: {},
            5: {},
            6: {},
            7: {},
            8: {},
            9: {},
            10: {},
            11: {}
        }

        const months = this.state.months

        for (let i = 0; i < allData.length; i++) {
            const date = new Date(allData[i].current_period_start * 1000)
            const valid =  date.getFullYear() === currentYear ? true : false
            if (valid) {
                const currentData = graphData[date.getMonth()]
                
                if (currentData[allData[i].price.price_id]) {
                    currentData[allData[i].price.price_id].mount += allData[i].plan.amount/100
                } else {
                    currentData[allData[i].price.price_id] = {
                      month: months[date.getMonth()],
                      mount: allData[i].plan.amount/100
                    }
                }
            }
        }

        graphData = Object.values(graphData)
        return graphData
    }

    createGraph = (data) => {

      return this.props.pricesData.map(value => {
        return {
          label: value.nickname,
          data: data.map(field => {
            const mount = field[value.price_id] && field[value.price_id].mount ? field[value.price_id].mount : 0
            return mount
          }),
          borderWidth: 2,
          backgroundColor: 'rgba(139, 195, 74, 0.4)',
          borderColor: getRandomColor(),
          lineTension: 0.1,
          fill: false, 
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: getRandomColor(),
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: getRandomColor(),
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
        }
      })
    }

    render(){
        return (
           <Grid container style={{paddingTop: '32px', width: "100%"}}>
              
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{paddingTop: '32px'}}>
                    <Grid container spacing={3}>
                        <CardContent style={{width: "100%"}}>
                        <DataTable 
                            title="Subscriptions List" 
                            data={[...this.state.dataForTable]} 
                            columns={this.state.columns} 
                            grouping={true}
                            selection={false}
                            exportButton={true}
                            toolbar={true}
                            noActions={true}
                        />
                        </CardContent>
                    </Grid>
                    </Grid>
                </Grid>
                
                
                <Grid item xs={12}>
                {
                  this.state.graphData.length > 0 ?
                    <LineGraph
                      data={ this.state.graphData }
                      labels={this.state.months}
                      title="Total sales per month"
                    />   
                    : null
                  }
                </Grid>
           </Grid>
        )
    }
};

export default ReportPlansComparisons;