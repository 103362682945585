import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { lightGreen } from '@material-ui/core/colors';


// Pages
import Landing from './containers/landing/Landing.js';
import Login from './containers/login/Login.js';
import SignUp from './containers/sign_up/SignUp.js';
import EditCurrentUser from './containers/edit_current_user/EditCurrentUser.js';
import Privacy from './containers/privacy/Privacy.js';
import Terms from './containers/terms/Terms.js';
// Tablas
import UsersTable from './containers/tables/UsersTable.js';
import PricesTable from './containers/tables/PricesTable.js';
import ProductsTable from './containers/tables/ProductsTable.js';
import Reports from './containers/reports/Reports.js';


// Providers
import { UserProvider } from "./providers/UserProvider";

// Tema de la web (colores)
const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: lightGreen[500],
      light: lightGreen[200],
      dark: lightGreen[900],
      contrastText: '#ffffff',
    },
    // secondary: {
    //   main: '#11cb5f',
    // },
    // email : admin@cluegarden.com,
    // pass: cluegarden2020
  },
});
// Tema de la web (colores)


const app = () => {
  
  return (<UserProvider>
    <App></App>
  </UserProvider>);
}

export default app

function App() {

  
  const validateLogin = (props, theme, login) => {
    if (localStorage.getItem("userToken")) {
      if (localStorage.getItem("userData") && localStorage.getItem("userData").indexOf("isAdmin") !== -1) {
        return (
          <Redirect to="/users" />
        )
      } else {
        return (
          <Redirect to="/" />
        )
      }
    } else {
      if (login) {
        return <Login {...props} theme={theme} />
      } else {
        return <SignUp {...props} theme={theme} />
      }
    }
  }

  const validateAcces = (component) => {
    if (localStorage.getItem("userToken")) {
      if (localStorage.getItem("userData") && localStorage.getItem("userData").indexOf("isAdmin") !== -1) {
        return component
      } else {
        return (
          <Redirect to="/" />
        )
      }
    } else {
      return <Redirect to="/login" />
    }
  }

  const validateAccesPlan = (component) => {
    if (localStorage.getItem("userToken")) {
      if (localStorage.getItem("userData") && localStorage.getItem("userData").indexOf("isAdmin") === -1) {
        return component
      } else {
        return (
          <Redirect to="/users" />
        )
      }
    } else {
      return component
    }
  }

  return (
    <div className="container-web-app">
      <ThemeProvider theme={theme}>
        <Router>
          <main>
              <Switch>
                <Route
                  exact
                  path='/login'
                  render={ (props) => validateLogin(props, theme, true) }
                />

                <Route
                  exact
                  path='/sign_up'
                  render={ (props) => validateLogin(props, theme) }
                />
                {/* Lists */}
                <Route
                  exact
                  path='/users'
                  render={ (props) => validateAcces(<UsersTable {...props} theme={ theme } />) }
                />

                <Route
                  exact
                  path='/prices'
                  render={ (props) => validateAcces(<PricesTable {...props} theme={ theme } />) }
                />

                <Route
                  exact
                  path='/products'
                  render={ (props) => validateAcces(<ProductsTable {...props} theme={ theme } />) }
                />
                
                <Route
                  exact
                  path='/reports'
                  render={ (props) => validateAcces(<Reports {...props} theme={ theme } />) }
                />
                
                {/* Lists */}

                <Route
                  exact
                  path='/edit_user'
                  render={ (props) => validateAccesPlan(<EditCurrentUser {...props} theme={ theme } />) }
                />
                             
                {/* <Route
                  exact
                  path='/payment'
                  render={(props) => <Component {...props} theme={ theme } />}
                /> */}

                <Route
                  path='/terms'
                  render={ (props) => <Terms {...props} theme={ theme }/> }
                />
                <Route
                  path='/privacy'
                  render={ (props) => <Privacy {...props} theme={ theme }/> }
                />
                <Route
                  path='*'
                  render={ (props) => <Landing {...props} theme={ theme }/> }
                />
              </Switch>
            </main>
        </Router>
      </ThemeProvider>
    </div>
  )
}
