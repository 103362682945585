import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';

// Components
import Menu from '../../components/menu/Menu';
import DataTable from '../../components/datatable/DataTable';
import ModalCG from '../../components/modal_cg/ModalCG';
import Modal from '@material-ui/core/Modal';
import ProductForm from '../product_form/ProductForm.js';

// Services
import { listStripeAllRecordsHandler } from '../../services/firebase';
import { handlerProductStatus } from '../../services/stripeServices';

// Constants
const defaultStyle = {
  cellStyle: {
      textAlign : 'center'
  },
}

class ProductsTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      products : [],
      modalForm: false,
      dataToEdit: null
    };
  }
  
  componentDidMount(){
    this.callTableData()
  }

  callTableData = () =>{
    listStripeAllRecordsHandler('product')
    .then((result) => {
      let products = result.docs.map(res => {
        return {
          ...res.data(),
          id: res.id
        }
      })
      this.setState({products})
    })
    .catch((error) => {
      console.log(error)
    })
  }

  onRowClick = (event, rowData) => {
    console.log(rowData)
  }

  editPdroductForm = (event, rowData) => {
    this.setState({ modalForm: true, dataToEdit: event })
  }

  cleanModalData = () => {
    this.setState({ modalForm: false, dataToEdit: null })
  }

  closeModalAndUpdate = () => {
    this.setState({ modalForm: false, dataToEdit: null })
    this.callTableData()
  }

  goToForm = ()=> {
    this.setState({ modalForm: true })
  }

  handlerDisabled = (row) => {
    handlerProductStatus(row.product_id, {active: !row.active})
    .then(response => {
      if (response) {
        let newproductList = this.state.products
  
        newproductList = newproductList.map( value => {
          if (value.product_id === row.product_id) {
            value.active = !row.active
          }
          return value
        })
        
        this.setState({ products: newproductList })
      }

    })
    .catch(error => {
      console.log(error)
    })
  }

  continueDelete = ()=> {
    this.handlerModalTable()
    this.handlerDisabled(this.state.rowData)
  }

  handlerModalTable = ()=> {
    this.setState({ openModal: !this.state.openModal })
  }

  handleStatus = (rowData)=>{
    this.handlerModalTable();
    this.setState({ rowData })
  }

  render() {
    const columns = [
      { 
        title: 'Name', 
        field: 'name',
        ...defaultStyle
      },
      { 
        title: 'Description', 
        field: 'description', 
        ...defaultStyle
      },
      { 
        title: 'Product ID', 
        field: 'product_id', 
        ...defaultStyle
      },
      { 
        title: 'Active', 
        field: 'active',
        render: rowData => {
          return(
            <Switch
              checked={rowData.active}
              onChange={()=> this.handleStatus(rowData) }
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          )
        },
        ...defaultStyle
      }
    ]
    
    return (
      <div>
        <Grid item xs={12} display="flex" justifyContent="center" > 
          <Menu />
          <Box display="flex" justifyContent="center">
            <Grid item xs={12} sm={10} md={10} >
              <Grid container spacing={2} style={{paddingTop: '32px'}}>
                  <Grid item >
                    <Typography component="h1" variant="h5">
                      Products
                    </Typography>
                  </Grid>
                  <Grid item >
                    <Button
                      variant="contained"
                      color="primary"
                      // className={classes.button}
                      startIcon={<AddCircleIcon />}
                      onClick={this.goToForm}
                    > 
                      Add
                    </Button>
                  </Grid>
              </Grid>
              <Container maxWidth={false} style={{paddingTop: '32px'}}>
                <Grid container spacing={3}>
                  <CardContent style={{width: "100%"}}>
                    <DataTable 
                        title="Products List" 
                        data={[...this.state.products]} 
                        columns={columns} 
                        grouping={true}
                        selection={false}
                        exportButton={true}
                        toolbar={true}
                        showSelectAllCheckbox={false}
                        actionsColumnIndex={-1}
                        onRowClick={this.onRowClick}
                        editTada={ this.editPdroductForm}
                    />
                  </CardContent>
                </Grid>
            </Container>
          </Grid>
        </Box>
      </Grid>

        <ModalCG
            openModal={this.state.openModal}
            type="continue"
            title="Are you sure you want to continue?"
            btcCancel={{
                    text:"Cancel",
                    action: this.handlerModalTable
                }}
            btnAccept={{
                    text: "Continue",
                    action: this.continueDelete
                }}
        />

        <Modal
          open={ this.state.modalForm }
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ProductForm closeModalForm={ this.cleanModalData } closeModalAndUpdate={ this.closeModalAndUpdate } dataToEdit={this.state.dataToEdit}/>
        </Modal>
      </div>
    )
  }
}

export default ProductsTable;