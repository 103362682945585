import React, { useState, useMemo, useEffect, createContext } from 'react';
import { auth } from "../services/firebase";

export const UserContext = createContext({ currentUser: null });

export const UserProvider = (props) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    async function getCurrentUser(){
      auth.onAuthStateChanged((userAuth) => {
        setCurrentUser(userAuth);
      });
    }
    getCurrentUser();
  }, [])

  const value = useMemo( () => {
      return({
        currentUser,
      })
  }, [currentUser]);

  return <UserContext.Provider value = {value} {...props} />
}