
import React, { Component } from 'react';

// Material UI Components
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

// Components
import AlertForms from '../../components/alert_forms/AlertForms';
import ModalCG from '../../components/modal_cg/ModalCG';

// Services
import { postCreateProduct, updateProduct } from '../../services/stripeServices';

// .CSS
import './ProductForm.css'

class ProductForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
        name: '',
        description: '',
        unit_label: '',
        nameError: null,
        descriptionError: null,
        erroAletMesagge: null,
        successAletMesagge: null,
        loading: false
    };
  }
  
  componentDidMount(){
    if (this.props.dataToEdit) {
        this.setState({ ...this.props.dataToEdit })    
    }
  }
  
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  validateSaveProducts = ()=> {
      
    let {
        name,
        description,
    } = this.state;

    let nameError = null
    let descriptionError = null
    let erroAletMesagge = null

    let error = false;

    if (name.trim() === '') {
        error = true
        nameError = 'Name input is required'
    }

    if (description.trim() === '') {
        error = true
        descriptionError = 'Description input is required'
    }

    this.setState({ 
        nameError,
        descriptionError,
        erroAletMesagge,
        successAletMesagge: null
    })

    if (!error) { 
        let data = {};
        data.name = this.state.name;
        data.description = this.state.description;
        if (this.state.unit_label) data.unit_label = this.state.unit_label;

        this.setState({ loading: true })
        if (this.props.dataToEdit) {
            // Edit Product
            // this.state.product_id === prod_IYoONtLAkKxwkF
            updateProduct(this.state.product_id, data)
            .then(() => {
                this.setState({
                    loading: false,
                    successAletMesagge: 'Product Updated successfully',
                    openModal: true
                })
            })
            .catch((error) => {
                if (error.request && error.request.response) {
                        const text = JSON.parse(error.request.response)
                        this.setState({
                            loading: false,
                            erroAletMesagge: text.message
                        })
                    }else{
                        this.setState({
                            loading: false,
                            erroAletMesagge: 'An error occurred'
                        })
                    }
            })
        }else{
            postCreateProduct(data)
            .then(() => {
                this.setState({
                    loading: false,
                    successAletMesagge: 'Product Save successfully',
                    openModal: true
                })
            })
            .catch((error) => {
                if (error.request && error.request.response) {
                        const text = JSON.parse(error.request.response)
                        this.setState({
                            loading: false,
                            erroAletMesagge: text.message
                        })
                    }else{
                        this.setState({
                            loading: false,
                            erroAletMesagge: 'An error occurred'
                        })
                    }
            })
        }
    }
  }

  redirectToTable = ()=> {
    this.setState({openModal: false})
    this.props.closeModalAndUpdate()
  }

// Products:
// - images (Fotos pero esto puede ser ponerlo luego)

  render() {
    return (
        <Grid 
            container
            justifyContent="center"
            alignItems="center"
            className="class-center-forms"
            xs={12} > 

            <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="class-cont-forms"
            xs={6}
            >
            
            <div style={{ width: '100%'}}>
               {this.state.loading && <LinearProgress />}
            </div>

                <Typography component="h1" variant="h4" style={ {marginTop: '20px'} } >
                    {this.props.dataToEdit ? 'Edit' : 'Add'} Product
                </Typography>
                
                <div className="form-tag-login-container space-login-container" noValidate>
                    {/*  */}
                    {
                        this.state.erroAletMesagge &&
                        <div className="container-alert-validations">
                            <AlertForms severity="error"> {this.state.erroAletMesagge} </AlertForms>
                        </div>
                    }

                    {/*  */}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <TextField
                            error={this.state.nameError ? true : false}
                            helperText={ this.state.nameError ? this.state.nameError : null }
                            autoComplete="fname"
                            name="name"
                            variant="outlined"
                            required
                            fullWidth
                            value={this.state.name}
                            id="name"
                            label="Name"
                            autoFocus
                            onChange={ (e)=> this.onChange(e) }
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                            error={this.state.descriptionError ? true : false}
                            helperText={ this.state.descriptionError ? this.state.descriptionError : null }
                            required
                            variant="outlined"
                            fullWidth
                            value={ this.state.description }
                            id="description"
                            label="Description"
                            name="description"
                            autoComplete="lname"
                            onChange={ (e)=> this.onChange(e) }
                        />
                        </Grid>
                    </Grid>
                    <div className="space-login-container container-btns-modal-forms">
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className="btns-forms-mdals"
                            disabled={this.state.loading}
                            onClick={ this.props.closeModalForm }
                        >
                            Cancel
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="btns-forms-mdals"
                            onClick={ this.validateSaveProducts }
                            disabled={this.state.loading}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </Grid>

            <ModalCG
            openModal={this.state.openModal}
            type="succes"
            title={this.state.successAletMesagge}
            btnAccept={{
                    text: "Accept",
                    action: this.redirectToTable
                }}
            />

        </Grid>
    )
  }
}

export default ProductForm;
