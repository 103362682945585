import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';

// Components
import Menu from '../../components/menu/Menu';
import DataTable from '../../components/datatable/DataTable';

// Services
import { listStripeAllRecordsHandler } from '../../services/firebase';
import { handlerPriceStatus } from '../../services/stripeServices';
import ModalCG from '../../components/modal_cg/ModalCG';
import Modal from '@material-ui/core/Modal';
import PriceForm from '../price_form/PriceForm.js';

// Constants
const defaultStyle = {
  cellStyle: {
      textAlign : 'center',
      openModal: false
  },
}

class PricesTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      prices : [],
      modalForm: false,
      dataToEdit: null
    };
  }
  
  componentDidMount(){
    this.callTableData()
  }

  callTableData = ()=>{
    listStripeAllRecordsHandler('price')
    .then((result) => {
      let prices = result.docs.map(res => {
        return {
          ...res.data(),
          id: res.id
        }
      })
      this.setState({prices})
    })
    .catch((error) => {
      console.log(error)
    })
  }

  onRowClick = (event, rowData) => {
    console.log(rowData)
  }
  
  editPriceForm = (event, rowData) => {
    this.setState({ modalForm: true, dataToEdit: event })
  }

  cleanModalData = () => {
    this.setState({ modalForm: false, dataToEdit: null })
  }

  closeModalAndUpdate = () => {
    this.setState({ modalForm: false, dataToEdit: null })
    this.callTableData()
  }

  goToForm = ()=> {
    this.setState({ modalForm: true })
  }

  handlerDisabled = (row) => {
    handlerPriceStatus(row.price_id, {active: !row.active})
    .then(response => {
      if (response) {
        let newPriceList = this.state.prices

        newPriceList = newPriceList.map( value => {
          if (value.price_id === row.price_id) {
            value.active = !row.active
          }
          return value
        })
        
        this.setState({ prices: newPriceList })
      }

    })
    .catch(error => {
      console.log(error)
    })
  }

  continueDelete = ()=> {
    this.handlerModalTable()
    this.handlerDisabled(this.state.rowData)
  }

  handlerModalTable = ()=> {
    this.setState({ openModal: !this.state.openModal })
  }

  handleStatus = (rowData)=>{
    this.handlerModalTable();
    this.setState({ rowData })
  }

  render() {
    const columns = [
      { 
        title: 'Nickname', 
        field: 'nickname',
        ...defaultStyle
      },
      { 
        title: 'Product', 
        field: 'product', 
        ...defaultStyle
      },
      { 
        title: 'Interval', 
        field: 'recurring.interval', 
        ...defaultStyle
      },
      { 
        title: 'Interval Count', 
        field: 'recurring.interval_count', 
        ...defaultStyle
      },
      { 
        title: 'Amount', 
        field: 'unit_amount', 
        ...defaultStyle
      },
      { 
        title: 'Amount Decimal', 
        field: 'unit_amount_decimal', 
        ...defaultStyle
      },
      { 
        title: 'Active', 
        field: 'active',
        render: rowData => {
          return(
            <Switch
              checked={rowData.active}
              onChange={()=> this.handleStatus(rowData) }
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          )
        },
        ...defaultStyle
      }
    ]
    return (
      <div>
        <Grid item xs={12} display="flex" justifyContent="center" > 
          <Menu />
          <Box display="flex" justifyContent="center">
            <Grid xs={12} sm={10} md={10}>

            <Grid container spacing={2} style={{paddingTop: '32px'}}>
                  <Grid item >
                    <Typography component="h1" variant="h5">
                      Prices
                    </Typography>
                  </Grid>
                  <Grid item >
                    <Button
                      variant="contained"
                      color="primary"
                      // className={classes.button}
                      startIcon={<AddCircleIcon />}
                      onClick={this.goToForm}
                    > 
                      Add
                    </Button>
                  </Grid>
              </Grid>

              <Container maxWidth={false} style={{paddingTop: '32px'}}>
                <Grid container spacing={3}>
                  <CardContent style={{width: "100%"}}>
                    <DataTable 
                        title="Prices List" 
                        data={[...this.state.prices]} 
                        columns={columns} 
                        grouping={true}
                        selection={false}
                        exportButton={true}
                        toolbar={true}
                        showSelectAllCheckbox={false}
                        actionsColumnIndex={-1}
                        onRowClick={this.onRowClick}
                        editTada={ this.editPriceForm}
                    />
                  </CardContent>
                </Grid>
            </Container>
          </Grid>
        </Box>
        </Grid>
        <ModalCG
            openModal={this.state.openModal}
            type="continue"
            title="Are you sure you want to continue?"
            btcCancel={{
                    text:"Cancel",
                    action: this.handlerModalTable
                }}
            btnAccept={{
                    text: "Continue",
                    action: this.continueDelete
                }}
        />

        <Modal
          open={ this.state.modalForm }
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <PriceForm closeModalForm={ this.cleanModalData } closeModalAndUpdate={ this.closeModalAndUpdate } dataToEdit={this.state.dataToEdit}/>
        </Modal>
      </div>
    )
  }
}

export default PricesTable;