import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

// Material UI Components
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';

// Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Components
import AlertForms from '../../components/alert_forms/AlertForms';

// Context
import { UserContext } from "../../providers/UserProvider";

// Services
import { signInHandler, resetUserPassword, getToken, loginFacebook, loginGoogle, activateFreePlan } from '../../services/firebase';
import { verifyCustomerAndRetrieveUser, createCheckoutSessionHandler } from '../../services/stripeServices';

// Helper
import { validateEmail } from '../../helpers/validateInputs';

// Assets
import logow3 from "../../assets/logo-cg-icon-w3.png";
import logoText from "../../assets/logo-cg-w2.png";

// .CSS
import './Login.css'

class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      emailError: null,
      passwordError: null,
      erroAletMesagge: null,
      showForgot: false,
      loading: false,
      redirectRoute: null,
    };
  }

  componentDidMount() {
    console.log('Inicializando componente login', this.props)
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handlerForgot = () => {
    this.setState({ showForgot: !this.state.showForgot })
  }

  senEmail = () => {
    this.setState({ showForgot: !this.state.showForgot })
    resetUserPassword(this.state.email)
  }

  validateLoginForm = () => {
    let {
      email,
      password
    } = this.state;

    let emailError = null
    let passwordError = null
    let error = false;

    if (email.trim() === '') {
      error = true
      emailError = 'Email input is required'
    } else if (!validateEmail(email)) {
      error = true
      emailError = 'Email format invalid'
    }

    if (password.trim() === '') {
      error = true
      passwordError = 'Password input is required'
    } 
    // else if (password.length < 6) {
    //   error = true
    //   passwordError = 'Password (6+ characters)'
    // }

    this.setState({
      emailError,
      passwordError,
      erroAletMesagge: ''
    })

    if (!error) {
      this.setState({ loading: true })
      signInHandler(email, password)
        .then(isLogged => {
          if (isLogged) {
            getToken()
              .then((idToken) => {
                localStorage.setItem("userToken", idToken)
                // setTimeout(() => {
                this.verifyCustomerAndRetrieveResponseHandler(isLogged.user.uid, isLogged.user.email);
                // }, 500);
              })
              .catch((error) => {
                console.log('Problem whit token');
              })
          } else {
            this.setState({ erroAletMesagge: 'Please check your email to continue' })
          }
        })
        .catch((err) => {
          if (err.code === "auth/wrong-password") {
            this.setState({
              loading: false,
              password: ''
            })
          }
          this.setState({
            loading: false,
            erroAletMesagge: err.message
          })
        })
    }
  }

  loginWithGoogleHandler = () => {
    this.setState({ loading: true })
    loginGoogle()
      .then((user) => {
        getToken()
          .then((idToken) => {
            localStorage.setItem("userToken", idToken)
            setTimeout(() => {
              this.verifyCustomerAndRetrieveResponseHandler(user.uid, user.email)
            }, 500);
          })
          .catch((error) => {
            console.log('Problem whit token');
          })
      })
      .catch((err) => {
        this.setState({
          loading: false,
          erroAletMesagge: err.message
        })
      })
  }

  loginWithFacebookHandler = () => {
    this.setState({ loading: true })
    loginFacebook()
      .then((user) => {
        if (user && user.emailVerified) {
          getToken()
            .then((idToken) => {
              localStorage.setItem("userToken", idToken)
              setTimeout(() => {
                this.verifyCustomerAndRetrieveResponseHandler(user.uid, user.email)
              }, 500);
            })
            .catch((error) => {
              console.log('Problem whit token');
            })
        } else {
          this.setState({ erroAletMesagge: 'Please check your email to continue', loading: false })
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
          erroAletMesagge: err.message
        })
      })
  }
  
  // response.data.emailVerified
  verifyCustomerAndRetrieveResponseHandler = (uid, email) => {
    verifyCustomerAndRetrieveUser(uid, email)
      .then((response) => {
        if ( !response.data.disabled) {
          localStorage.setItem('userData', JSON.stringify(response.data))
          if (response.data.isAdmin) {
            this.setState({redirectRoute : "/users"});
          } else if (!response.data.subscription_id && this.props.location && this.props.location.state && this.props.location.state.redirectToStripe && this.props.location.state.priceID ) {
            if (this.props.location.state.priceID === "sub_free") {
              activateFreePlan(response.data.uid, response.data.email)
              .then(() => {
                window.location.replace("/#pricing");
              })
              .catch((err) => {
                console.log(err);
                window.location.replace("/#pricing");
              })
            } else {
              createCheckoutSessionHandler(this.props.location.state.priceID, response.data.uid);
            }
          } else {
            if (this.props.location && this.props.location.state && this.props.location.state.redirectToStripe) {
              window.location.replace("/#pricing");
            } else {
              this.setState({redirectRoute : "/"});
            }
          }
        } else {
          localStorage.clear()
          this.setState({
            loading: false,
            erroAletMesagge: 'Check your email by going to your inbox if you have already done so maybe your username was deleted'
          })
        }
      })
      .catch((error) => {
        console.log(error)
        localStorage.clear()
        this.setState({
          loading: false,
          erroAletMesagge: 'Something went wrong'
        })
      })
  }

  goToSignUp = () => {
    let from = { pathname: '/sign_up' }
    this.props.history.push(from);
  }

  render() {
    const { theme } = this.props
    return (
      <div className="container-login-element color-form-cont">
        { this.state.redirectRoute && 
          <Redirect 
            to={{
              pathname: `${this.state.redirectRoute}`,
            }}
          />
        }
        <div style={{ width: '100%' }}>
          {this.state.loading && <LinearProgress />}
        </div>
        <CssBaseline />
        <Container component="div" maxWidth="xs" className="center-login-element">
          <div className="cont-img-header-login">
            <img src={logow3} alt="logo" className="img-header-login-logo" />
            <img src={logoText} alt="textLogo" className="img-header-login-textLogo" />
          </div>
          {
            this.state.erroAletMesagge &&
            <div className="container-alert-validations" style={{ marginTop: '20px' }}>
              <AlertForms severity="error"> {this.state.erroAletMesagge} </AlertForms>
            </div>
          }
          <div className="cont-text-login" >
            <span> Log in to your account: </span>
          </div>

          <div className="form-tag-login-container" noValidate>
            <div className="container-icon-login">
              <input
                type="text"
                id="email"
                name="email"
                autoComplete="email"
                value={this.state.email}
                placeholder="Email address"
                onChange={(e) => this.onChange(e)}
                className="inputs-for-users"
              />
              {this.state.emailError &&
                <div className="show-eror-iput-user"> {this.state.emailError} </div>
              }
            </div>

            <div className="container-icon-login">
              <input
                id="password"
                name="password"
                value={this.state.password}
                type="password"
                onChange={(e) => this.onChange(e)}
                placeholder="Password (6+ characters)"
                className="inputs-for-users"
              />

              {this.state.passwordError &&
                <div className="show-eror-iput-user"> {this.state.passwordError} </div>
              }

            </div>

            <div className="btn-options-login">
              {/* <div className="btn-options-login-icons">
                <div onClick={this.loginWithFacebookHandler}>
                  <i className="fab fa-facebook-f"></i>
                </div >
                <div onClick={this.loginWithGoogleHandler}>
                  <i className="fab fa-google"></i>
                </div>
              </div> */}

              <div>
                <div className="text-to-center">
                  <span onClick={this.handlerForgot}>
                    Forgot password?
                  </span>
                </div>
                <div className="cont-continue-btn" onClick={this.validateLoginForm}>
                  <span
                    disabled={this.state.loading}
                    className="btn-forms-c-g">
                    Continue
                  </span>
                </div>
              </div>
            </div>

            <div className="dont-have-account" >
              <span>
                Don't have an account?
              </span>

              <span onClick={this.goToSignUp} >
                Sign Up
              </span>
            </div>

          </div>
        </Container>

        <Dialog open={this.state.showForgot} onClose={this.handlerForgot} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Forgot your password?</DialogTitle>
          <DialogContent>
            <DialogContentText>
            No problem, we'll send a link to your email so you can recover it. See you soon!
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              name="email"
              value={this.state.email}
              label="Email"
              type="email"
              onChange={(e) => this.onChange(e)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handlerForgot} color="primary">
              Cancel
            </Button>
            <Button onClick={this.senEmail} color="primary">
              Send Email
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default Login;
