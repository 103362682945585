import React from 'react';
import { Line } from 'react-chartjs-2';

const LineGraph = (props) => {
  const data = {
    datasets: [
      {
        label: 'Amount',
        // yAxisID: "y-LastWeekData",
        // hidden: true,
        data: props.data,
        borderWidth: 2, // The line width (in pixels).
        backgroundColor: 'rgba(139, 195, 74, 0.4)', // The line fill color.
        borderColor: 'rgba(139, 195, 74, 1)', // The line color.
        lineTension: 0.1, // Bezier curve tension of the line. Set to 0 to draw straightlines. This option is ignored if monotone cubic interpolation is used.
        fill: false,  // How to fill the area under the line
        borderCapStyle: 'butt', // Cap style of the line
        borderDash: [], // Length and spacing of dashes
        borderDashOffset: 0.0,  // Offset for line dashes
        borderJoinStyle: 'miter', // Line joint style
        pointBorderColor: 'rgba(139, 195, 74, 1)', // The border color for points.
        pointBackgroundColor: '#fff', // The fill color for points.
        pointBorderWidth: 1,  // The width of the point border in pixels.
        pointHoverRadius: 5,  // The radius of the point when hovered.
        pointHoverBackgroundColor: 'rgba(139, 195, 74, 1)', // Point background color when hovered.
        pointHoverBorderColor: 'rgba(220,220,220,1)', // Point border color when hovered.
        pointHoverBorderWidth: 2, // Border width of point when hovered.
        pointRadius: 1, // The radius of the point shape. If set to 0, the point is not rendered.
        pointHitRadius: 10,  // The pixel size of the non-displayed point that reacts to mouse events.
      }
    ],
    labels: props.labels ? props.labels : [],
  };

  const options = {
    title: {
      display: true,
      text: props.title
    },
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Y'
        }
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'X'
        }
      }],
    }
  };

  return (
    <Line
      data={data}
      options={options}
    />
  )
}

export default LineGraph;