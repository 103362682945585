import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

// Service

import { auth } from '../../services/firebase';

// .CSS
import './Menu.css'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  backColor: {
    background: theme.palette.primary.main
  },
  title: {
    flexGrow: 1,
  },
}));

export default function MenuCG({ theme }) {
  const classes = useStyles();
  const [menu, handlerlMenu] = useState(true);

  const signOut = ()=>{
      auth.signOut().then(function() {
          localStorage.clear();
          window.location.pathname = '/'
      }).catch(function(error) {
          alert('Algo salio mal')
      });
  }
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton onClick={ ()=> handlerlMenu(!menu) } edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            ClueGarden Admin
          </Typography>
          <Button color="inherit" onClick={ signOut }>Logout</Button>
          
            <menu className={`cont-menu-nav ${classes.backColor}`} style={{ left: menu ? '-26vw' : '0vw' }} >

              <IconButton onClick={ ()=> handlerlMenu(!menu) } edge="start" className="menu-icon-app" color="inherit" aria-label="menu">
                <MenuIcon />
              </IconButton>
              
              <Box display="flex" justifyContent="space-between" flexDirection="column" marginTop="80px" height="200px">

                <Link className="route-menu-links" to="users">Users</Link>
                <Link className="route-menu-links" to="products">Products</Link>
                <Link className="route-menu-links" to="prices">Prices</Link>
                <Link className="route-menu-links" to="/reports">Reports</Link>
                <Link className="route-menu-links" to="/logs">Logs</Link>

              </Box>

            </menu>
          
        </Toolbar>
      </AppBar>
    </div>
  );
}