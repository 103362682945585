
import React, { Component, Fragment } from 'react';

// Material UI Components
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Components
import AlertForms from '../../components/alert_forms/AlertForms';
import ModalCG from '../../components/modal_cg/ModalCG';
import LinearProgress from '@material-ui/core/LinearProgress';

// Services
import { postCreatePrice, updatePrice } from '../../services/stripeServices';
import { listStripeAllRecordsHandler } from '../../services/firebase';

// .CSS
import './PriceForm.css'

class PriceForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
        nickname: '',
        unit_label: '',
        unit_amount: '',
        // interval: null,
        interval_count: '',
        unit_amountError: null,
        nicknameError: null,
        productError: null,
        interval_countError: null,
        successAletMesagge: null,
        data: null,
        loading: false
    };
  }
  
  componentDidMount(){
    listStripeAllRecordsHandler('product')
    .then((result) => {
        let products = result.docs.map(res => {
            return {
                ...res.data(),
                id: res.id
            }
        })
        products = products.filter(value => value.active)

        this.setState({products})
    }).catch((error) => {
        console.log(error);
    })

    if (this.props.dataToEdit) {
        this.setState({ data: this.props.dataToEdit, nickname: this.props.dataToEdit.nickname })
    }
  }
  
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  validateSavePrices = ()=> {
    let error = false
    let {
        unit_amount,
        nickname,
        product,
        interval,
        interval_count
    } = this.state;

    let unit_amountError = null
    let nicknameError = null
    let productError = null
    let interval_countError = null
    let erroAletMesagge = null

    if (nickname.trim() === '') {
        error = true
        nicknameError = 'Plan name input is required'
    }

    if (!this.state.data) {
        if (unit_amount.trim() === '') {
            error = true
            unit_amountError = 'Unit Amount input is required'
        }else if(isNaN(unit_amount)){
            error = true
            unit_amountError = 'Unit Amount must be a number'
        }

        if (!product) {
            error = true
            productError = 'Product input is required'
        }
        if (interval && interval.trim() !== '') {
            if (interval_count.trim() !== '') {
                if ( (interval === "year" && Number(interval_count) > 1 || Number(interval_count) === 0 ) ||
                     (interval === "month" && Number(interval_count) > 12 || Number(interval_count)  === 0 ) ||
                     (interval === "week" && Number(interval_count) > 52 || Number(interval_count)  === 0 )
                 ) {
                    error = true
                    interval_countError = 'Numbers > 0 or max 1 year, 12 month o 52 week'
                }
            }
        }
    }


    this.setState({ 
        unit_amountError,
        nicknameError,
        productError,
        interval_countError,
        erroAletMesagge,
    })

    if (!error) { 
        let data = this.state.data ? this.state.data : {
                currency: "usd",
                product: product.product_id,
                nickname: nickname,
                recurring: {
                    interval: interval,
                    interval_count: interval_count,
                },
                unit_amount: unit_amount
            }
        this.setState({ loading: true })
        if (this.props.dataToEdit) {

            updatePrice(data.price_id, {nickname: nickname})
            .then(() => {
                this.setState({
                    loading: false,
                    successAletMesagge: 'Price Updated successfully',
                    openModal: true
                })
            })
            .catch((error) => {
                
                let errorData = 'Error Updated price';
                if (error.response && error.response.data && error.response.data.raw && error.response.data.raw.message) {
                    errorData = error.response.data.raw.message
                }
                this.setState({
                    loading: false,
                    erroAletMesagge: errorData,
                })

            })
        }else{
            postCreatePrice(data)
            .then((response) => {
                this.setState({
                    loading: false,
                    successAletMesagge: 'Price saved successfully',
                    openModal: true
                })
            })
            .catch((error) => {
                
                let errorData = 'Error saved price';
                if (error.response && error.response.data && error.response.data.raw && error.response.data.raw.message) {
                    errorData = error.response.data.raw.message
                }
                this.setState({
                    loading: false,
                    erroAletMesagge: errorData,
                })

            })
        }
    }
  }

onChangeAutoComplete = (e, value) => {
    this.setState({ product: value })
}

redirectToTable = ()=> {
    this.setState({openModal: false})
    this.props.closeModalAndUpdate()
}

  render() {
    return (
        <Grid 
            container
            justifyContent="center"
            alignItems="center"
            className="class-center-forms"
            xs={12} > 
            
            <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="class-cont-forms"
            xs={6}
            >

            <div style={{ width: '100%'}}>
               {this.state.loading && <LinearProgress />}
            </div>

                <Typography component="h1" variant="h4" style={ {marginTop: '20px'} } >
                    {this.props.dataToEdit ? 'Edit' : 'Add'} Prices
                </Typography>
                
                <div className="form-tag-login-container space-login-container" noValidate>
                    
                    {
                        this.state.erroAletMesagge &&
                        <div className="container-alert-validations">
                            <AlertForms severity="error"> {this.state.erroAletMesagge} </AlertForms>
                        </div>
                    }

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <TextField
                            error={this.state.nicknameError ? true : false}
                            helperText={ this.state.nicknameError ? this.state.nicknameError : null }
                            variant="outlined"
                            fullWidth
                            value={ this.state.nickname }
                            id="nickname"
                            label="Plan Name"
                            name="nickname"
                            autoComplete="nickname"
                            required
                            onChange={ (e)=> this.onChange(e) }
                        />
                        </Grid>
                    {
                        !this.state.data &&
                        <Fragment>
                            
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    getOptionLabel={(option) => option.name}
                                    options={ this.state.products }
                                    onChange={ (e, value)=> this.onChangeAutoComplete(e, value) }
                                    value={ this.state.product }
                                    renderInput={(params) => 
                                    <TextField 
                                        {...params}
                                        fullWidth
                                        error={this.state.productError ? true : false}
                                        helperText={ this.state.productError ? this.state.productError : null }
                                        variant="outlined"
                                        id="product"
                                        name="product"
                                        autoComplete="product"
                                        label="Product" 
                                        required
                                    />}
                                />
                            </Grid>

                            <Grid item xs={12}>
                            <TextField
                                error={this.state.unit_amountError ? true : false}
                                helperText={ this.state.unit_amountError ? this.state.unit_amountError : null }
                                required
                                variant="outlined"
                                fullWidth
                                value={ this.state.unit_amount }
                                type="number"
                                id="unit_amount"
                                label="Unit Amount"
                                name="unit_amount"
                                autoComplete="unit_amount"
                                onChange={ (e)=> this.onChange(e) }
                            />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="demo-simple-select-outlined-label">Interval</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    name="interval"
                                    value={this.state.interval}
                                    onChange={ (e)=> this.onChange(e) }
                                    label="Interval"
                                    >
                                    <MenuItem value="year" >Year</MenuItem>
                                    <MenuItem value="month" >Month</MenuItem>
                                    <MenuItem value="week" >Week</MenuItem>
                                    
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                            <TextField
                                error={this.state.interval_countError ? true : false}
                                helperText={ this.state.interval_countError ? this.state.interval_countError : null }
                                variant="outlined"
                                fullWidth
                                value={ this.state.interval_count }
                                type="number"
                                id="interval_count"
                                label="Interval Count"
                                name="interval_count"
                                autoComplete="interval_count"
                                onChange={ (e)=> this.onChange(e) }
                            />
                            </Grid>
                        </Fragment>
                    }
                    </Grid>
                    <div className="space-login-container container-btns-modal-forms">
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className="btns-forms-mdals"
                            disabled={this.state.loading}
                            onClick={ this.props.closeModalForm }
                        >
                            Cancel
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="btns-forms-mdals"
                            onClick={ this.validateSavePrices }
                            disabled={this.state.loading}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </Grid>

            <ModalCG
            openModal={this.state.openModal}
            type="succes"
            title={this.state.successAletMesagge}
            btnAccept={{
                    text: "Accept",
                    action: this.redirectToTable
                }}
            />

        </Grid>
    )
  }
}

export default PriceForm;
