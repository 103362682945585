import React, { Component, Fragment } from 'react';

// Material UI Components
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import LinearProgress from '@material-ui/core/LinearProgress';

// Components
import ModalCG from '../../components/modal_cg/ModalCG';

// Helper
import { validateEmail } from '../../helpers/validateInputs';

// Services
import { updateUser, resetUserPassword, postCreateUserServer } from '../../services/firebase';    

import AlertForms from '../../components/alert_forms/AlertForms';
// .CSS
import './UserForm.css'

class UserForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
        firstname: '',
        lastname: '',
        email: '',
        address: '',
        postalCode: '',
        password: '',
        passwordRepeat: '',
        firstNameError: null,
        emailError: null,
        passwordError: null,
        passwordRepeatError: null,
        erroAletMesagge: null,
        successAletMesagge: null,
        showPassword: false,
        showPasswordRepeat: false,
        resetPass: false,
        loading: false
    };
  }
  
  componentDidMount(){
    if (this.props.dataToEdit) {
        this.setState({ ...this.props.dataToEdit })    
    }
  }
  
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleClickShowPassword = (pass) => {
    this.setState({[pass]: !this.state[pass] })
  };

  validateSignUpForm = ()=> {
      
    let {
        firstname,
        email,
        password,
        passwordRepeat
    } = this.state;

    let firstNameError = null
    let emailError = null
    let passwordError = null
    let passwordRepeatError = null
    let erroAletMesagge = null

    let error = false;

    if (firstname.trim() === '') {
        error = true
        firstNameError = 'first name input is required'
    }

    if (email.trim() === '') {
        error = true
        emailError = 'Email input is required'
    }else if (!validateEmail(email)) {
        error = true
        emailError = 'Email format invalid'
    }

    if (password.trim() === '' && !this.props.dataToEdit) {
        error = true
        passwordError = 'Password input is required'
    }

    if (passwordRepeat.trim() === '' && !this.props.dataToEdit) {
        error = true
        passwordRepeatError = 'Repeat password input is required'
    }

    this.setState({ 
        firstNameError,
        emailError,
        passwordError,
        passwordRepeatError,
        erroAletMesagge,
        successAletMesagge: null
    })

    if (!error) { 
        if (password === passwordRepeat) {
            let data = {};
            data.displayName = `${this.state.firstname}${this.state.lastname ? " " + this.state.lastname : ""}`;
            data.firstname = this.state.firstname;
            if (!this.props.dataToEdit) {
                data.password = password;
            }
            
            data.email = email;
            if (this.state.lastname) data.lastname = this.state.lastname;
            if (this.state.address) data.address = this.state.address;
            if (this.state.postalCode) data.postalCode = this.state.postalCode;
            this.setState({ loading: true })
            if (this.props.dataToEdit) {
                // Edit User
                updateUser(this.state.uid, data)
                .then(() => {
                    this.setState({
                        loading: false,
                        successAletMesagge: 'User Updated successfully',
                        openModal: true
                    })
                })
                .catch((error) => {
                    if (error.request && error.request.response) {
                        const text = JSON.parse(error.request.response)
                        this.setState({
                            loading: false,
                            erroAletMesagge: text.message
                        })
                    }else{
                        this.setState({
                            loading: false,
                            erroAletMesagge: 'An error occurred'
                        })
                    }
                })
            }else{
                postCreateUserServer(data)
                .then((user) => {
                    this.setState({
                        loading: false,
                        successAletMesagge: 'User saved successfully',
                        openModal: true
                    })
                })
                .catch(err => {
                    if (err.request && err.request.response) {
                        const text = JSON.parse(err.request.response)
                        this.setState({
                            loading: false,
                            erroAletMesagge: text.message
                        })
                    }else{
                        this.setState({
                            loading: false,
                            erroAletMesagge: 'An error occurred'
                        })
                    }
                })
            }
        } else {
            this.setState({ 
                erroAletMesagge: 'Passwords do not match',
                passwordError: 'Check here',
                passwordRepeatError: 'Check here',
             })
        }
    }
  }

  redirectToTable = ()=> {
      this.setState({openModal: false})
      this.props.closeModalAndUpdate()
  }
  
  resetPass = () =>{
      if (this.state.email) {
          resetUserPassword(this.state.email)
          this.setState({
            successAletMesagge: 'Reset Password sent to the user`s email',
            openModal: true,
            resetPass: true
        })
      }
  }

  accepResetPass = () => {
    this.setState({
        openModal: false,
        resetPass: false
    })
  }

  render() {
    return (
        <Grid 
            container
            justifyContent="center"
            alignItems="center"
            className="class-center-forms"
            xs={12} 
            > 

            <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="class-cont-forms"
            xs={6}
            >        
            <div style={{ width: '100%'}}>
               {this.state.loading && <LinearProgress />}
            </div>
                <Typography component="h1" variant="h4" style={ {marginTop: '20px'} } >
                    {this.props.dataToEdit ? 'Edit' : 'Add'} User
                </Typography>
                
                <div className="form-tag-login-container space-login-container" noValidate>

                    {
                        this.state.erroAletMesagge &&
                        <div className="container-alert-validations">
                            <AlertForms severity="error"> {this.state.erroAletMesagge} </AlertForms>
                        </div>
                    }

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                        <TextField
                            error={this.state.firstNameError ? true : false}
                            helperText={ this.state.firstNameError ? this.state.firstNameError : null }
                            autoComplete="fname"
                            name="firstname"
                            variant="outlined"
                            required
                            fullWidth
                            value={this.state.firstname}
                            id="firstname"
                            label="First Name"
                            autoFocus
                            onChange={ (e)=> this.onChange(e) }
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={ this.state.lastname }
                            id="lastname"
                            label="Last Name"
                            name="lastname"
                            autoComplete="lname"
                            onChange={ (e)=> this.onChange(e) }
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                            error={this.state.emailError ? true : false}
                            helperText={ this.state.emailError ? this.state.emailError : null }
                            variant="outlined"
                            required
                            fullWidth
                            value={ this.state.email }
                            id="email"
                            label="Email address"
                            name="email"
                            autoComplete="email"
                            onChange={ (e)=> this.onChange(e) }
                            disabled={ this.props.dataToEdit ? true : false }
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={ this.state.address }
                            id="address"
                            label="address"
                            name="address"
                            autoComplete="address"
                            onChange={ (e)=> this.onChange(e) }
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={ this.state.postalCode }
                            name="postalCode"
                            label="Postal code"
                            type="text"
                            id="postalCode"
                            autoComplete="postal-code"
                            onChange={ (e)=> this.onChange(e) }
                        />
                        </Grid>

                        {
                            !this.props.dataToEdit &&
                            <Fragment>
                                <Grid item xs={12}>
                                <div className="container-icon-login">
                                    <TextField
                                        error={this.state.passwordError ? true : false}
                                        helperText={ this.state.passwordError ? this.state.passwordError : null }
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="password"
                                        label="Password"
                                        name="password"
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        autoComplete="password"
                                        onChange={ (e)=> this.onChange(e) }
                                    />
                                <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={ ()=> this.handleClickShowPassword('showPassword') }
                                            edge="end"
                                            className="icons-sing-up-btn"
                                        >
                                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </div>
                                </Grid>
                                <Grid item xs={12}>
                                <div className="container-icon-login">
                                    <TextField
                                        error={this.state.passwordRepeatError ? true : false}
                                        helperText={ this.state.passwordRepeatError ? this.state.passwordRepeatError : null }
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="passwordRepeat"
                                        label="Confirm Password"
                                        type={this.state.showPasswordRepeat ? 'text' : 'password'}
                                        id="passwordRepeat"
                                        autoComplete="repeat-password"
                                        onChange={ (e)=> this.onChange(e) }
                                    />
                                    <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={ ()=> this.handleClickShowPassword('showPasswordRepeat') }
                                            edge="end"
                                            className="icons-sing-up-btn"
                                        >
                                            {this.state.showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </div>
                                </Grid>
                            </Fragment>
                        }
                        {
                            this.props.dataToEdit &&
                            <Grid item xs={12}>
                                <Button
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        disabled={this.state.loading}
                                        startIcon={<RotateLeftIcon />}
                                        onClick={ this.resetPass }
                                >
                                    Reset Password
                                </Button>
                            </Grid>
                        }

                    </Grid>
                    <div className="space-login-container container-btns-modal-forms">
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className="btns-forms-mdals"
                            disabled={this.state.loading}
                            onClick={ this.props.closeModalForm }
                        >
                            Cancel
                        </Button>

                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="btns-forms-mdals"
                            disabled={this.state.loading}
                            onClick={ this.validateSignUpForm }
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </Grid>

            <ModalCG
            openModal={this.state.openModal}
            type="succes"
            title={this.state.successAletMesagge}
            btnAccept={{
                    text: "Accept",
                    action: this.state.resetPass ? this.accepResetPass : this.redirectToTable
                }}
            />
        </Grid>
    )
  }
}

export default UserForm;
