import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from "material-table";

import './DataTable.css'

class DataTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render(){
        const { title, columns, data, grouping, selection, exportButton, toolbar,
            showSelectAllCheckbox, actionsColumnIndex, editHidden, onRowClick,
            noActions, editTada } = this.props
        return (
            <Fragment>
                <MaterialTable
                    style={{width: "100%"}}
                    title={title}
                    columns={columns}
                    data={[...data]}
                    onRowClick={onRowClick}
                    options={{
                        grouping,
                        selection,
                        exportButton,
                        toolbar,
                        showSelectAllCheckbox,
                        actionsColumnIndex,
                        headerStyle: {
                            textAlign : 'center',
                            backgroundColor: '#8bc34a',
                            color: '#FFF'
                        },
                        paging:true,
                        pageSize: 12,       // make initial page size
                        pageSizeOptions:[5,12,20,50],
                        // rowStyle: rowData => ({ 
                        //     backgroundColor: rowData.tableData.checked ? 'rgb(238, 238, 238)' : '',
                        // }),
                    }}
                    actions={noActions ? [] :
                    
                    [
                        {
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, rowData) => editTada(rowData),
                            hidden: editHidden
                        },
                        // {
                        //     icon: 'bar_chart',
                        //     tooltip: 'Statistics',
                        //     // onClick: (event, rowData) => handleRedirectEstadistica(rowData),
                        //     hidden: statisticHidden
                        // }
                    ]}
                />
            </Fragment>
        )
    }
};

DataTable.propTypes = {
    title: PropTypes.string,
    columns: PropTypes.array,
    grouping: PropTypes.bool,
    selection: PropTypes.bool,
    exportButton: PropTypes.bool,
    toolbar: PropTypes.bool,
    showSelectAllCheckbox: PropTypes.bool,
    actionsColumnIndex: PropTypes.number,
};

DataTable.defaultProps = {
    grouping: true, 
    selection: true,
    exportButton: true,
    toolbar: true,
    showSelectAllCheckbox: true,
    actionsColumnIndex: -1,
}
export default DataTable;