import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import 'date-fns';
import { CardContent } from '@material-ui/core';
import DataTable from '../../components/datatable/DataTable';

import './ReportTransactionsPerUser.css'

// Constants
const defaultStyle = {
    cellStyle: {
        textAlign : 'center',
        openModal: false
    },
  }
  
class ReportTransactionsPerUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataForTable: []
        };
    }

    componentDidMount(){        
      
      let allData = this.props.subscriptionData.map(subs => {
          const user = this.props.usersData.find((value) => value.customer === subs.customer_id )
          const price = this.props.pricesData.find((value) => value.price_id === subs.plan.id )
          subs.user = user
          subs.price = price
          return subs
      })
        
        this.setState({ dataForTable: allData })
    }
    

    render(){

        const columns = [
          { 
            title: 'Buy Date', 
            field: 'current_period_start',
            render: rowData => {
              const date = new Date(rowData.current_period_start * 1000).toLocaleDateString('en-US', {hour: 'numeric', year: 'numeric', month: 'long', day: 'numeric' })
              return(
                date
                )
              },
              ...defaultStyle
            },
            { 
              title: 'Expiration date', 
              field: 'current_period_end',
              render: rowData => {
                const date = new Date(rowData.current_period_end * 1000).toLocaleDateString('en-US', {hour: 'numeric', year: 'numeric', month: 'long', day: 'numeric' })
                return(
                  date
                  )
                },
                ...defaultStyle
              },
          { 
            title: 'Price Code', 
            field: 'price.price_id',
            ...defaultStyle
          },
          { 
            title: 'Price Name', 
            field: 'price.nickname',
            ...defaultStyle
          },
          { 
            title: 'User', 
            field: 'user.email', 
            defaultGroupOrder: 0,
            ...defaultStyle
          },
          { 
            title: 'Duration', 
            field: 'plan.interval', 
            ...defaultStyle
          },
          { 
            title: 'Amount', 
            field: 'plan.amount',
            render: rowData => {
              const amount = rowData.plan.amount ? rowData.plan.amount / 100 : 0
              return(
                amount
                )
              },
            ...defaultStyle
          },
          { 
            title: 'Subscription id', 
            field: 'subscription_id', 
            ...defaultStyle
          }
        ]
        return (
           <Grid container style={{paddingTop: '32px', width: "100%"}}  >

              <CardContent style={{width: "100%"}}>
                <DataTable 
                    title="Subscriptions List" 
                    data={[...this.state.dataForTable]} 
                    columns={columns} 
                    grouping={true}
                    selection={false}
                    exportButton={true}
                    toolbar={true}
                    noActions={true}
                    style={{width: "100%"}}
                />
              </CardContent>
           </Grid>
        )
    }
};

export default ReportTransactionsPerUser;