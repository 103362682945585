import React from 'react';
import { Line } from 'react-chartjs-2';

const LineGraph = (props) => {
  const data = {
    datasets: props.data,
    labels: props.labels ? props.labels : [],
  };

  const options = {
    title: {
      display: true,
      text: props.title
    },
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Y'
        }
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'X'
        }
      }],
    }
  };

  return (
    <Line
      data={data}
      options={options}
    />
  )
}

export default LineGraph;