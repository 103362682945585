import React, { Component, Fragment, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

// Material UI Components
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Helper
import { validateEmail } from '../../helpers/validateInputs';

// Services
import { signUpHandler, loginFacebook, loginGoogle, getToken } from '../../services/firebase';
import { verifyCustomerAndRetrieveUser } from '../../services/stripeServices';

// Components
import AlertForms from '../../components/alert_forms/AlertForms';
import ModalCG from '../../components/modal_cg/ModalCG';

import MomentUtils from '@date-io/moment';
import {
  DatePicker
} from '@material-ui/pickers';

// .CSS
import './SignUp.css'

import { countries } from '../../constants';

// Assets
import logow3 from "../../assets/logo-cg-icon-w3.png";
import logoText from "../../assets/logo-cg-w2.png";

const recaptchaRef = React.createRef();

class SignUp extends Component {


  constructor(props) {
    super(props);

    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      postalCode: '',
      password: '',
      birthdate: null,
      birthdateError: null,
      gender: null,
      genderError: null,
      country: null,
      countryError: null,
      firstNameError: null,
      emailError: null,
      passwordError: null,
      erroAletMesagge: null,
      postalCodedError: null,
      openModal: false,
      isVerified: false,
      loading: false,
      specialUpdates: true,
      termsConditions: false
    };
  }

  componentDidMount() {
    console.log('Inicializando componente sign up', this.props)
  }

  countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  }

  handleClickShowPassword = (pass) => {
    this.setState({ [pass]: !this.state[pass] })
  };

  onChange = (event) => {
    const name = event.target.name
    let value = (name === "specialUpdates" || name === "termsConditions") ? event.target.checked : event.target.value;
    this.setState({ [name]: value })
  }

  onChangeAutoComplete = (e, value) => {
    this.setState({ country: value })
  }

  onChangeAutoCompleteGender = (e, value) => {
    this.setState({ gender: value })
  }

  recaptchaHandler = (response) => {
    console.log(response)
    if (response) {
      this.setState({
        isVerified: true
      })
    }
  }

  validateSignUpForm = () => {
    let {
      firstname,
      lastname,
      email,
      password,
      isVerified,
      postalCode,
      country,
      gender,
      birthdate,
      termsConditions
    } = this.state;

    let firstNameError = null
    let lastNameError = null
    let emailError = null
    let passwordError = null
    let passwordRepeatError = null
    let erroAletMesagge = null
    let postalCodedError = null
    let countryError = null
    let genderError = null
    let birthdateError = null

    let error = false;

    if (firstname.trim() === '') {
      error = true
      firstNameError = 'Name is required'
    }

    if (lastname.trim() === '') {
      error = true
      lastNameError = 'Last name is required'
    }

    if (email.trim() === '') {
      error = true
      emailError = 'Email input is required'
    } else if (!validateEmail(email)) {
      error = true
      emailError = 'Email format invalid'
    }

    if (password.trim() === '') {
      error = true
      passwordError = 'Password input is required'
    } else if (password.length < 6) {
      error = true
      passwordError = 'Password (6+ characters)'
    }

    // if (!country) {
    //   error = true
    //   countryError = 'Country input is required'
    // }

    // if (!birthdate) {
    //   error = true
    //   birthdateError = 'Birthdate input is required'
    // }

    // if (!gender) {
    //   error = true
    //   genderError = 'Gender input is required'
    // }

    // if (postalCode.trim() === '') {
    //   error = true
    //   postalCodedError = 'Postal Code input is required'
    // }

    if (!isVerified) {
      error = true
      erroAletMesagge = 'Please complete the reCaptcha'
    }

    if (!termsConditions) {
      error = true
      erroAletMesagge = 'Please accept terms & conditions'
    }

    this.setState({
      firstNameError,
      lastNameError,
      emailError,
      passwordError,
      passwordRepeatError,
      erroAletMesagge,
      postalCodedError,
      countryError,
      genderError,
      birthdateError
    })

    if (!error) {
      let data = {};
      data.displayName = `${this.state.firstname}${this.state.lastname ? " " + this.state.lastname : ""}`;
      data.firstname = this.state.firstname;
      if (this.state.lastname) data.lastname = this.state.lastname;
      if (this.state.postalCode) data.postalCode = this.state.postalCode;
      if (this.state.gender) data.gender = this.state.gender;
      if (this.state.birthdate) data.birthdate = new Date(this.state.birthdate);
      if (this.state.country) data.country = this.state.country;
      data.specialUpdates = this.state.specialUpdates;
      data.termsConditions = this.state.termsConditions;
      this.setState({ loading: true })
      signUpHandler(email, password, data)
        .then(isLogged => {
          this.setState({
            loading: false,
            openModal: true
          })
        })
        .catch(err => {
          recaptchaRef.current.reset()
          console.log(err);
          this.setState({ loading: false })
        })
      this.setState({
        loading: false
      })

    } else {
      recaptchaRef.current.reset()
    }
  }

  redirectToLogin = () => {
    let from = { pathname: '/login' }
    this.props.history.push(from);
  }

  goToLogin = () => {
    let from = { pathname: '/login' }
    this.props.history.push(from);
  }

  loginWithGoogleHandler = () => {
    this.setState({ loading: true })
    loginGoogle()
      .then((user) => {
        getToken()
          .then((idToken) => {
            localStorage.setItem("userToken", idToken)
            setTimeout(() => {
              this.verifyCustomerAndRetrieveResponseHandler(user.uid, user.email)
            }, 500);
          })
          .catch((error) => {
            console.log('Problem whit token');
          })
      })
      .catch((err) => {
        this.setState({
          loading: false,
          erroAletMesagge: err.message
        })
      })
  }

  loginWithFacebookHandler = () => {
    this.setState({ loading: true })
    loginFacebook()
      .then((user) => {
        if (user && user.emailVerified) {
          getToken()
            .then((idToken) => {
              localStorage.setItem("userToken", idToken)
              setTimeout(() => {
                this.verifyCustomerAndRetrieveResponseHandler(user.uid, user.email)
              }, 500);
            })
            .catch((error) => {
              console.log('Problem whit token');
            })
        } else {
          this.setState({ erroAletMesagge: 'Please check your email to continue', loading: false })
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
          erroAletMesagge: err.message
        })
      })
  }

  handleBirthdateChange = (e) => {
    this.setState({ birthdate: e })
    console.log(e);
  }

  verifyCustomerAndRetrieveResponseHandler = (uid, email) => {
    verifyCustomerAndRetrieveUser(uid, email)
      .then((response) => {
        if (response.data.emailVerified && !response.data.disabled) {
          localStorage.setItem('userData', JSON.stringify(response.data))
          let from = { pathname: '/plans' }
          if (response.data.isAdmin) {
            from.pathname = '/users'
          }
          this.props.history.push(from);
        } else {
          localStorage.clear()
          this.setState({
            loading: false,
            erroAletMesagge: 'Check your email by going to your inbox if you have already done so maybe your username was deleted'
          })
        }
      })
      .catch((error) => {
        localStorage.clear()
        this.setState({
          loading: false,
          erroAletMesagge: 'Something went wrong'
        })
      })
  }

  render() {
    const { theme } = this.props
    return (
      <div className="container-login-element color-form-cont cont-sign-up">
        <div style={{ width: '100%' }}>
          {this.state.loading && <LinearProgress />}
        </div>
        <CssBaseline />
        <Container component="div" maxWidth="xs" className="center-login-element">
          <div className="cont-img-header-login">
            <img src={logow3} alt="logo" className="img-header-login-logo" />
            <img src={logoText} alt="textLogo" className="img-header-login-textLogo" />
          </div>

          {this.state.openModal ?
            <div>
                <Typography component="h1" variant="h5" style={{
                        color: 'white',
                        marginTop: '40px'
                }}>
                    Welcome to cluegarden!
                </Typography>
                
                <Typography component="h5" variant="span" style={{
                        color: 'white',
                        marginTop: '20px'
                }}>
                    your account was created successfully. You can click below to log in
                </Typography>
                
                <div className="dont-have-account" >
                    <span onClick={this.goToLogin} >
                        Log in
                    </span>
                </div>
            </div>
            :

        <Fragment>

          <Typography component="h1" variant="h5" style={{
              color: 'white',
              marginTop: '20px'
            }}>
            Create a free account:
          </Typography>

          <Typography component="span" variant="span">
            No credit card required.
          </Typography>

          <div className="form-tag-login-container space-login-container" noValidate>
            {
              this.state.erroAletMesagge &&
              <div className="container-alert-validations">
                <AlertForms severity="error"> {this.state.erroAletMesagge} </AlertForms>
              </div>
            }

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  autoComplete="firstname"
                  value={this.state.firstname}
                  placeholder="Name*"
                  onChange={(e) => this.onChange(e)}
                  className="inputs-for-users"
                />

                {this.state.firstNameError &&
                  <div className="show-eror-iput-user"> {this.state.firstNameError} </div>
                }
              </Grid>
              <Grid item xs={12} sm={6}>
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  autoComplete="lastname"
                  value={this.state.lastname}
                  placeholder="Last Name*"
                  onChange={(e) => this.onChange(e)}
                  className="inputs-for-users"
                />

                {this.state.lastNameError &&
                  <div className="show-eror-iput-user"> {this.state.lastNameError} </div>
                }
              </Grid>
              <Grid item xs={12}>
                <input
                  type="text"
                  id="email"
                  name="email"
                  autoComplete="email"
                  value={this.state.email}
                  placeholder="Email address*"
                  onChange={(e) => this.onChange(e)}
                  className="inputs-for-users"
                />

                {this.state.emailError &&
                  <div className="show-eror-iput-user"> {this.state.emailError} </div>
                }
              </Grid>
              <Grid item xs={12}>
                <input
                  id="password"
                  name="password"
                  value={this.state.password}
                  type="password"
                  onChange={(e) => this.onChange(e)}
                  placeholder="Password (6+ characters)*"
                  className="inputs-for-users"
                />

                {this.state.passwordError &&
                  <div className="show-eror-iput-user"> {this.state.passwordError} </div>
                }
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="gender"
                  options={['Male', 'Female', 'Prefer not to say']}
                  disableClearable
                  disablePortal
                  getOptionLabel={(option) => option}
                  onChange={(e, value) => this.onChangeAutoCompleteGender(e, value)}
                  value={this.state.gender}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <input
                        {...params.inputProps}
                        type="text"
                        name="gender"
                        placeholder="Gender"
                        className="inputs-for-users"
                        autocomplete='disabled'
                      />
                    </div>
                  )}
                />

                {this.state.genderError &&
                  <div className="show-eror-iput-user"> {this.state.genderError} </div>
                }

              </Grid>
              <Grid item xs={12}>
              <DatePicker format="MM/DD/yyyy" value={this.state.birthdate} onChange={this.handleBirthdateChange} className="inputs-for-users date-sign-up" placeholder="Birthdate" />
                {/* <input
                  type="date"
                  name="birthdate"
                  data-placeholder="Birthdate"
                  placeholder="Birthdate"
                  value={this.state.birthdate}
                  onChange={(e) => this.onChange(e)}
                  required
                  max="2014-12-31"
                  aria-required="true"
                  className="inputs-for-users date-sign-up"
                /> */}
                {this.state.birthdateError &&
                  <div className="show-eror-iput-user"> {this.state.birthdateError} </div>
                }
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="country"
                  options={countries}
                  disableClearable
                  disablePortal
                  getOptionLabel={(option) => option.label}
                  onChange={(e, value) => this.onChangeAutoComplete(e, value)}
                  value={this.state.country}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span>{this.countryToFlag(option.code)}</span>
                      {option.label} ({option.code})
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <input
                        {...params.inputProps}
                        type="text"
                        name="country"
                        placeholder="Country"
                        className="inputs-for-users"
                        autocomplete='disabled'
                      />
                    </div>
                  )}
                />

                {this.state.countryError &&
                  <div className="show-eror-iput-user"> {this.state.countryError} </div>
                }

              </Grid>
              <Grid item xs={12}>
                <input
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  autoComplete="postalCode"
                  value={this.state.postalCode}
                  placeholder="Zip Code"
                  onChange={(e) => this.onChange(e)}
                  className="inputs-for-users"
                />

                {this.state.postalCodedError &&
                  <div className="show-eror-iput-user"> {this.state.postalCodedError} </div>
                }
              </Grid>
              <Grid item xs={12} className="cont-checkbox">
                <div>
                  <label>
                    <input
                      type='checkbox'
                      checked={this.state.specialUpdates}
                      onChange={e => this.onChange(e)}
                      name="specialUpdates"
                      id="specialUpdates"
                    />
                    <span></span>
                    Receive special updates from ClueGarden.
                  </label>
                </div>

                <div>
                  <label>
                    <input
                      type='checkbox'
                      checked={this.state.termsConditions}
                      onChange={e => this.onChange(e)}
                      name="termsConditions"
                      id="termsConditions"
                    />
                    <span></span>
                    I agree with the <a href="/terms" target="Blank" style={{ textDecoration: 'underline'}}>&nbsp;terms & conditions</a>.
                  </label>
                </div>

              </Grid>
              <Grid item xs={12} className="container-recaptcha">
                <ReCAPTCHA
                  sitekey="6LfU5bAaAAAAAHCiXPvZukM5dN6ZvCD58vkTU0Yc"
                  ref={recaptchaRef}
                  onChange={this.recaptchaHandler}
                />
              </Grid>
            </Grid>

            <div className="btn-options-login">
              {/* <div className="btn-options-login-icons">
                <div onClick={this.loginWithFacebookHandler}>
                  <i className="fab fa-facebook-f"></i>
                </div >
                <div onClick={this.loginWithGoogleHandler}>
                  <i className="fab fa-google"></i>
                </div>
              </div> */}

              <div>
                <button 
                className="cont-continue-btn" 
                onClick={this.validateSignUpForm}
                disabled={this.state.loading}
                >
                  <span
                    className="btn-forms-c-g"
                  >
                    Continue
                  </span>
                </button>
                <span className="sign-up-all-fields">*Required fields.</span>
              </div>
            </div>

            <div className="dont-have-account" >
              <span>
                Already have an account?
              </span>

              <span onClick={this.goToLogin} >
                Login
              </span>
            </div>

          </div>            
          </Fragment>
          }
        </Container>

      </div>
    )
  }
}

export default SignUp;