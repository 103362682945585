import React from 'react';
import MenuUsers from '../../components/menu_users/MenuUsers';

const myHTML = `

<!-- header area start  -->
<header class="header-simple-area header-bg-4 style-four" id="home">
</header>
<!-- header area end  -->

<!-- video area start -->
<section class="legal-area">
  <div class="top-bg"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="right-content-area">
                    <h1 class="title">Privacy Policy</h1>
                    <hr />
                    <br />
                    <h5><b>Our Commitment To Your Privacy</b></h5>
                    <p>We take your privacy seriously, and we are committed to protecting your personal data. This notice explains our privacy practices and how you can expect data to be collected and used.</p>
                    <br />
                    <h5><b>Our Privacy Principles</b></h5>
                    <p>We don't use your data to show you ads, and we only store the data that we need to make ClueGarden function as a secure and feature-rich application.</p>
                    <br />
                    <h5><b>Information We Collect</b></h5>
                    <p>When you register for a ClueGarden account, we only ask for your name and email.</p>
                    <p>You have the option to provide more information, if you want, such as your main interest in using ClueGarden. We use that information to tailor your ClueGarden experience to your personal goal.</p>
                    <p>If you log into ClueGarden using your Facebook account, Facebook may send us some information about you. That information helps us identify you correctly.</p>
                    <p>In order to keep improving ClueGarden, we collect analytics data that measures traffic and usage trends. This data is completely anonymous.</p>
                    <br />
                    <h5><b>How We Use The Information</b></h5>
                    <p>We will use your name and email only to contact you directly about ClueGarden. We do not share any personal information with advertisers or outside parties, except for the purpose of payment when you order a subscription.</p>
                    <br />
                    <h5><b>Commitment To Data Security</b></h5>
                    <p>We secure your information with both physical and electronic protections, and we maintain strict procedures to safeguard the information collected.</p>
                    <br />
                    <h5><b>How To Contact Us</b></h5>
                    <p>If you have any questions or concerns regarding our privacy policy, please <a href="index.html#contact"><b>contact us</b></a>.</p>
                    <br />
                    <p><b>Thank you. And now, enjoy ClueGarden! ☺</b></p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- video area end -->

<!-- footer area start -->
<footer class="footer-area">
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-5">
                    <div class="footer-widget about_widget">
                        <a href="index.html" class="footer-logo"><img src="assets/img/logo-white.png" alt=""></a>
                        <p>A guide for your self-discovery adventure.</p>
                        <ul class="social-icon">
                            <li><a href="https://www.facebook.com/cluegarden" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="https://www.instagram.com/cluegarden/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                            <li><a href="https://twitter.com/cluegarden" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/cluegarden" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6">
                    <div class="footer-widget nav_menus_widget">
                        <h4 class="widget-title">Useful Links</h4>
                        <ul>
                            <li><a href="index.html"><i class="fas fa-chevron-right"></i> Home</a></li>
                            <li><a href="index.html#what"><i class="fas fa-chevron-right"></i> What</a></li>
                            <li><a href="index.html#how"><i class="fas fa-chevron-right"></i> How</a></li>
                            <li><a href="index.html#faqs"><i class="fas fa-chevron-right"></i> FAQs</a></li>
                            <li><a href="index.html#pricing"><i class="fas fa-chevron-right"></i> Pricing</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6">
                    <div class="footer-widget nav_menus_widget">
                        <h4 class="widget-title">Need help?</h4>
                        <ul>
                            <!-- <li><a href="#"><i class="fas fa-chevron-right"></i> Faqs</a></li> -->
                            <li><a href="index.html#contact"><i class="fas fa-chevron-right"></i> Support</a></li>
                            <li><a href="privacy"><i class="fas fa-chevron-right"></i> Privacy Policy</a></li>
                            <li><a href="terms"><i class="fas fa-chevron-right"></i> Terms &<br /> &nbsp; &nbsp; Conditions</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area"><!-- copyright area -->
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="copyright-inner"><!-- copyright inner wrapper -->
                        <div class="left-content-area"><!-- left content area -->
                            &copy; ClueGarden. All rights reserved.
                        </div><!-- //. left content aera -->
                    </div><!-- //.copyright inner wrapper -->
                </div>
            </div>
        </div>
    </div><!-- //. copyright area -->
</footer>
<!-- footer area end -->


  <!-- back to top area start -->
  <div class="back-to-top">
        <i class="fas fa-angle-up"></i>
  </div>
  <!-- back to top area end -->
  `

export  default function Privacy() {
    return (
        <div>
                <MenuUsers/>
            <div dangerouslySetInnerHTML={{ __html: myHTML }} />
        </div>
    );
  }