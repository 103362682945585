import React, { Component } from 'react';
import Menu from '../../components/menu/Menu';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Reports
import ReportSubscription from '../../components/report_subscription/ReportSubscription';
import ReportSubscriptionByPlan from '../../components/report_subscription_by_plan/ReportSubscriptionByPlan';
import ReportTransactionsPerUser from '../../components/report_transactions_per_user/ReportTransactionsPerUser';
import ReportPlansComparisons from '../../components/report_plans_comparisons/ReportPlansComparisons';
import ReportPlansUsersTimeline from '../../components/report_plans_users_timeline/ReportPlansUsersTimeline';
// Services
import { listStripeAllRecordsHandler } from '../../services/firebase';
import { getUsers } from '../../services/firebase';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{width: "100%"}}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


class Reports extends Component {

  constructor(props) {
    super(props);

    this.state = {
      subscriptionData: [],
      pricesData: [],
      usersData: [],
      freeSubscriptionsData: [],
      tabStatus: 0
    };
  }
  
  componentDidMount(){
    
      listStripeAllRecordsHandler('subscription')
      .then((result) => {
        let subscriptionData = result.docs.map(res => {
          return {
            ...res.data(),
            id: res.id
          }
        })
        this.setState({subscriptionData})
      })
      .catch((error) => {
        console.log(error)
      })

      listStripeAllRecordsHandler('price')
      .then((result) => {
          let pricesData = result.docs.map(res => {
              return {
                  ...res.data(),
                  id: res.id
              }
          })
          this.setState({pricesData})
      }).catch((error) => {
          console.log(error);
      })

      listStripeAllRecordsHandler('free_subscription')
      .then((result) => {
          let freeSubscriptionsData = result.docs.map(res => {
              return {
                  ...res.data(),
                  id: res.id
              }
          })
          this.setState({freeSubscriptionsData})
      }).catch((error) => {
          console.log(error);
      })

      getUsers()
      .then( async result => {
        let usersData = result;
        this.setState({usersData});
      })
      .catch(error => {
        console.log(error)
      })
    
  }

  handleChangeTabs = (event, newValue)=>{
    this.setState({ tabStatus: newValue })
  }

  render() {
    
    return (
      <div>
        <Menu />
        <Grid 
          sm={12} 
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <h1> Reports </h1>
          <Grid
            container
            sm={10}
          >
            <AppBar position="static">
              <Tabs 
                value={this.state.tabStatus}
                onChange={this.handleChangeTabs} 
                aria-label="simple tabs example"
                indicatorColor="primary"
                >
                <Tab label="SUBSCRIPTION" {...a11yProps(0)} />
                <Tab label="SUBSCRIPTIONS BY PLAN" {...a11yProps(1)} />
                <Tab label="TRANSACTIONS PER USER" {...a11yProps(2)} />
                <Tab label="SALES PLANS COMPARISONS" {...a11yProps(2)} />
                <Tab label="TIME LINE USERS" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <TabPanel value={this.state.tabStatus} index={0}>
              {
                this.state.subscriptionData.length > 0 && this.state.pricesData.length > 0 && this.state.usersData.length > 0 ? 
                <ReportSubscription
                  subscriptionData={ this.state.subscriptionData}
                  pricesData={ this.state.pricesData}
                  usersData={ this.state.usersData}
                />
                :
                <h4> Loading Data ...</h4>
              }
            </TabPanel>
            <TabPanel value={this.state.tabStatus} index={1}>
              {
                this.state.subscriptionData.length > 0 && this.state.pricesData.length > 0 && this.state.usersData.length > 0 ? 
                <ReportSubscriptionByPlan
                  subscriptionData={ this.state.subscriptionData}
                  pricesData={ this.state.pricesData}
                  usersData={ this.state.usersData}
                />
                :
                <h4> Loading Data ...</h4>
              }
            </TabPanel>
            <TabPanel value={this.state.tabStatus} index={2}>
              {
                this.state.subscriptionData.length > 0 && this.state.pricesData.length > 0 && this.state.usersData.length > 0 ? 
                <ReportTransactionsPerUser
                  subscriptionData={ this.state.subscriptionData}
                  pricesData={ this.state.pricesData}
                  usersData={ this.state.usersData}
                />
                :
                <h4> Loading Data ...</h4>
              }
            </TabPanel>
            <TabPanel value={this.state.tabStatus} index={3}>
              {
                this.state.subscriptionData.length > 0 && this.state.pricesData.length > 0 && this.state.usersData.length > 0 ? 
                <ReportPlansComparisons
                  subscriptionData={ this.state.subscriptionData}
                  pricesData={ this.state.pricesData}
                  usersData={ this.state.usersData}
                />
                :
                <h4> Loading Data ...</h4>
              }
            </TabPanel>
            <TabPanel value={this.state.tabStatus} index={4}>
              {
                this.state.subscriptionData.length > 0 && this.state.pricesData.length > 0 && this.state.usersData.length > 0 ? 
                <ReportPlansUsersTimeline
                  subscriptionData={ this.state.subscriptionData}
                  pricesData={ this.state.pricesData}
                  usersData={ this.state.usersData}
                />
                :
                <h4> Loading Data ...</h4>
              }
            </TabPanel>

          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Reports;