import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

// .CSS
import './ModalCG.css'

class ModalCG extends Component {

  constructor(props) {
    super(props);

    this.state = {
    };
  }
  
  componentDidMount(){
  }

  render() {

    return (
        <Modal
            open={this.props.openModal}
            onClose={ this.props.type === 'continue' ? this.props.btcCancel.action : this.props.btnAccept.action }
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
        <div className="modal-table-cotainer" >
                <div className="modal-body-table-cotainer" >
                    
                    <Typography component="h4" variant="h5" style={ {marginTop: '20px'} } >
                        { this.props.title }
                    </Typography>
                    
                    {
                        this.props.type === 'continue' &&

                        <div className="container-btn-modal-table" >
                            <Button
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    className="btn-modal-table-btn"
                                    onClick={ this.props.btcCancel.action }
                                >
                                    {this.props.btcCancel.text}
                            </Button>

                            <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="btn-modal-table-btn"
                                    onClick={ this.props.btnAccept.action }
                                >
                                    {this.props.btnAccept.text}
                            </Button>
                        </div>
                    }

                    {
                        (this.props.type === 'succes' || this.props.type === 'error') &&
                        <Button
                            fullWidth
                            variant="contained"
                            color={this.props.type === 'succes' ? "primary" : "secondary"}
                            onClick={ this.props.btnAccept.action }
                        >
                            {this.props.btnAccept.text}
                        </Button>
                    }

            
                </div>
            </div>
        
        </Modal>
    )
  }
}

export default ModalCG;